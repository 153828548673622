.Loader {
    display: block;
    width: 64px;
    height: 64px;
    margin: 1px auto;
  }

.Loader:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px auto;
    border-radius: 50%;
    border: 5px solid rgb(0, 0, 0);
    border-color: rgb(0, 0, 0) transparent rgb(0, 0, 0) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}