.Container {
    width: 100%;
    height: 90vh;
    background-color: gray;
}

.AppIcon {
    width: 10%;
    margin: 0 auto;
}

.Centered {
    background-color: white;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    width: 40%;
    max-width: 400px;
    height: 80%;
    max-height: 500px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.Form {
    box-shadow: 10px 10px 33px 5px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    width: 100%;
    margin: 0;
    padding: 10px;
}

.LinkButton {
    margin-top: 50px;
}