#dropAreaContainer {
  display: inline-block;
  position: relative;
  /* width: fit-content; */
  margin: 0 auto;
  min-height: 50vh;
}

#dropAreaBorder {
    border: dashed grey 3px;
    background-color: rgba(255,255,255,.8);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
}

#dropAreaOverlay {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    text-align: center;
    color: grey;
    font-size: 36px;
}
